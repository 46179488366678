<script lang="ts">
import Vue from 'vue';
import YoutubeEmbedLite from '../../app/components/youtube-embed/YouTubeEmbedLite.vue';

export default Vue.extend({ components: { YoutubeEmbedLite } });
</script>

<template>
  <main>
    <section class="pt-16 pb-20 bg-gray-50">
      <div class="container px-4 max-w-7xl mx-auto">
        <div class="flex flex-wrap items-center mb-12">
          <div class="w-full lg:w-1/2 mb-6 lg:mb-0">
            <h2 class="text-4xl font-bold font-heading">
              <span>Digital check-in software that </span>
              <span class="text-red-600">streamlines </span>
              <span>your front desk and enhances visitor experience </span>
            </h2>
          </div>
          <div class="w-full lg:w-1/2">
            <p class="lg:pl-16 text-gray-500 leading-loose"> {{ $t('content.indexFeaturesSection1.sectionSubtitle') }}</p>
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 -mb-6">
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
            <div class="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
              <svg
                class="w-6 h-6 mx-auto mb-4 text-red-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                ></path>
              </svg>
              <h3 class="mb-2 font-bold font-heading">{{ $t('content.indexFeaturesSection1.featureBlocks[0].fields.title') }}</h3>
              <p class="text-sm text-gray-500">{{ $t('content.indexFeaturesSection1.featureBlocks[0].fields.content') }}</p>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
            <div class="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
              <svg
                class="w-6 h-6 mx-auto mb-4 text-red-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                ></path>
              </svg>
              <h3 class="mb-2 font-bold font-heading">{{ $t('content.indexFeaturesSection1.featureBlocks[1].fields.title') }}</h3>
              <p class="text-sm text-gray-500">{{ $t('content.indexFeaturesSection1.featureBlocks[1].fields.content') }}</p>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
            <div class="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
              <svg
                class="w-6 h-6 mx-auto mb-4 text-red-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
              </svg>
              <h3 class="mb-2 font-bold font-heading">{{ $t('content.indexFeaturesSection1.featureBlocks[2].fields.title') }}</h3>
              <p class="text-sm text-gray-500">{{ $t('content.indexFeaturesSection1.featureBlocks[2].fields.content') }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-16 mb-20">
      <div class="container max-w-6xl m-4 mx-auto shadow">
        <YoutubeEmbedLite vid="u1VX3922VUk" thumb-quality="maxres" :params="{ start: 0 }" />
        <!-- <div class="vid-wrap">
          <object class="vid">
            <param name="movie" value="https://www.youtube.com/embed/u1VX3922VUk" />
            <embed height="100%" width="100%" src="https://www.youtube.com/embed/u1VX3922VUk" />
          </object>
        </div> -->
      </div>
    </section>

    <section class="pt-20 pb-12 md:pb-8">
      <div class="container px-4 max-w-7xl mx-auto">
        <div class="flex flex-wrap -mx-3">
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div class="flex items-start">
              <div class="mr-6 p-3 bg-red-500 rounded text-white">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                </svg>
              </div>
              <div class="mt-4">
                <h3 class="mb-3 text-xl font-semibold font-heading">{{ $t('content.indexFeaturesSection2.features[0].fields.title') }}</h3>
                <p class="text-base text-gray-500 leading-loose">{{ $t('content.indexFeaturesSection2.features[0].fields.content') }}</p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div class="flex items-start">
              <div class="mr-6 p-3 bg-red-500 rounded text-white">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                  ></path>
                </svg>
              </div>
              <div class="mt-4">
                <h3 class="mb-3 text-xl font-semibold font-heading">{{ $t('content.indexFeaturesSection2.features[1].fields.title') }}</h3>
                <p class="text-base text-gray-500 leading-loose">{{ $t('content.indexFeaturesSection2.features[1].fields.content') }}</p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div class="flex items-start">
              <div class="mr-6 p-3 bg-red-500 rounded text-white">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  ></path>
                </svg>
              </div>
              <div class="mt-4">
                <h3 class="mb-3 text-xl font-semibold font-heading">{{ $t('content.indexFeaturesSection2.features[2].fields.title') }}</h3>
                <p class="text-base text-gray-500 leading-loose">{{ $t('content.indexFeaturesSection2.features[2].fields.content') }}</p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div class="flex items-start">
              <div class="mr-6 p-3 bg-red-500 rounded text-white">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  ></path>
                </svg>
              </div>
              <div class="mt-4">
                <h3 class="mb-3 text-xl font-semibold font-heading">{{ $t('content.indexFeaturesSection2.features[3].fields.title') }}</h3>
                <p class="text-base text-gray-500 leading-loose">{{ $t('content.indexFeaturesSection2.features[3].fields.content') }}</p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div class="flex items-start">
              <div class="mr-6 p-3 bg-red-500 rounded text-white">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                  ></path>
                </svg>
              </div>
              <div class="mt-4">
                <h3 class="mb-3 text-xl font-semibold font-heading">{{ $t('content.indexFeaturesSection2.features[4].fields.title') }}</h3>
                <p class="text-base text-gray-500 leading-loose">{{ $t('content.indexFeaturesSection2.features[4].fields.content') }}</p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div class="flex items-start">
              <div class="mr-6 p-3 bg-red-500 rounded text-white">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  ></path>
                </svg>
              </div>
              <div class="mt-4">
                <h3 class="mb-3 text-xl font-semibold font-heading">{{ $t('content.indexFeaturesSection2.features[5].fields.title') }}</h3>
                <p class="text-base text-gray-500 leading-loose">{{ $t('content.indexFeaturesSection2.features[5].fields.content') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style>
.vid-wrap {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.vid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
