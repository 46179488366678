





























































































.thumb:hover .ytp-large-play-button-bg {
  transition: fill 0.1s cubic-bezier(0, 0, 0.2, 1), fill-opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
  fill: #f00;
  fill-opacity: 1;
}
